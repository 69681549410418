import React from "react"
import Layout from "../components/shared/layout/layout"
import SEO from "../components/shared/ui-elements/seo"
import "../pages/404.scss"
import {Link} from "gatsby";
import {useIntl} from "gatsby-plugin-intl/index";

const NotFoundPage = () => {

    const intl = useIntl();
    return (
        <Layout>
            <SEO title="404: Not found"/>
            <div className='container-404'>
                <div className='container__top'>
                    <p>  {intl.formatMessage({id: `404`})}</p>
                </div>
                <div className="container__bottom">
                    <div className='container__bottom__404'>
                        <p>404</p>
                    </div>
                    <div className='container__bottom__helpful'>
                        <p>Helpful Links</p>
                        <ul>
                            <li>
                                <div className='helpful__icon'><p>></p></div>
                                <Link
                                    className='helpful__link'
                                    to={"/"}
                                    activeClassName="active">
                                    {intl.formatMessage({id: `header.home`})}
                                </Link>
                            </li>
                            <li>
                                <div className='helpful__icon'><p>></p></div>
                                <Link
                                    className='helpful__link'
                                    to={"/chalk-it-page"}
                                    activeClassName="active">
                                    {intl.formatMessage({id: `header.chalk-it`})}
                                </Link>
                            </li>
                            <li>
                                <div className='helpful__icon'><p>></p></div>
                                <Link
                                    className='helpful__link'
                                    to={"products/"}
                                    activeClassName="active">
                                    {intl.formatMessage({id: `header.products`})}
                                </Link>
                            </li>
                            <li>
                                <div className='helpful__icon'><p>></p></div>
                                <Link
                                    className='helpful__link'
                                    to={"/where-to-buy"}
                                    activeClassName="active">
                                    {intl.formatMessage({id: `header.whereToBuy`})}
                                </Link>
                            </li>
                            <li>
                                <div className='helpful__icon'><p>></p></div>
                                <Link
                                    className='helpful__link'
                                    to={"/contacts"}
                                    activeClassName="active">
                                    {intl.formatMessage({id: `header.contacts`})}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/*<h1 className='one'>NOT FOUND</h1>*/}
            {/*<p>You just hit a route that doesn&#39;t exist... the sadness.</p>*/}
        </Layout>
    )
}

export default NotFoundPage
